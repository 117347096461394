<template>
	<div class="home-container">
		<div class="top-content">
			<div class="address-item" @click="submitReopen(index)">
				Elephant Gold Alliance
			</div>
		</div>
		<div class="info">
			<div class="info-total">
				<div class="info-total-item">
					<div class="item">
						<div class="title">{{$t('total')}}</div>
						<div class="num">{{ numFormatter(totalInfo.subscribe) }}<span class="symple">BNB</span></div>
					</div>
				</div>
				<div class="info-total-item other">
					<div class="item total">
						<div class="title">
							<div class="name">{{ $t('ketiqu') }}</div>
							<popover v-model="showPopover" trigger="click" theme="dark"  placement="bottom-end">
								<div class="popover-content">
									<div class="popover-title">
										<div class="name">{{$t('allIncome')}}</div>
										<div>{{ numFormatter(totalInfo.all) }}</div>
									</div>
									<div class="popover-title">
										<div class="name">{{ $t('poolPageIncome1') }}</div>
										<div>{{ numFormatter(totalInfo.income) }}</div>
									</div>
									<div class="popover-title">
										<div class="name">{{$t('poolPageIncome2')}}</div>
										<div>{{ numFormatter(totalInfo.team) }}</div>
									</div>
								</div>
								<template #reference>
									<van-icon name="more-o" />
								</template>
							</popover>
						</div>
						<div class="num">{{ numFormatter(totalInfo.extract) }}<span class="symple">BNB</span></div>
						<div class="btn" @click="teamOut(1)">{{$t('withraw')}}</div>
					</div>
				</div>
			</div>

      
			<div class="info-team">
				<div class="btn">
					<div class="copy" id="copy" :data-clipboard-text="url" @click="handleCopy">{{$t('copyLink')}}</div>
				</div>
				<!-- <div class="btn1">复制邀请链接</div> -->
				<div class="btn">{{$t('up')}}{{ hideAddress(teamInfo.paddress) }}</div>
				<div>{{$t('team')}}: <span class="coin">{{ numFormatter(teamInfo.performance) }}BNB</span></div>
				<div class="num">{{$t('team1')}}<span class="value">{{ numFormatter(teamInfo.people) }}</span>{{$t('team2')}} {{$t('team3')}}<span class="value">{{ numFormatter(teamInfo.push) }}</span>{{$t('team2')}}
				</div>
				<img src="@/image/team.png" alt="" class="img">
			</div>
			
			<div class="info-pool">
				<div class="info-pool-title">
					<div class="name">
						<img src="@/image/icon.png" alt="">
						<span>{{$t('poolPageBuy1')}}</span>
					</div>
					<div class="num">{{ totalInfo.yield }}%</div>
				</div>
				<div class="info-pool-submit" @click="showPopup1">{{$t('buy')}}</div>
			</div>
			<div class="info-title1">MY POOLS</div>
			<div class="info-list">
				<div class="info-list-tab">
					<div class="info-list-tab-item" v-for="(item, index) in listTab" :key="index"
						:class="index == tabActive ? 'active' : ''" @click="changeTab(index)">{{ item }}</div>
				</div>
				<div v-if="tabActive == 0">
					<div class="info-list-item" v-for="(item, index) in list" :key="index">
						<div class="info-list-item-title">
							<div class="name">{{$t('poolPagePool1')}}{{ numFormatter(item.num) }} BNB</div>
							<div v-if="item.day>item.remain" class="btn" @click="submitRedeem(item.mark)">{{$t('poolPagePool5')}}</div>
						</div>
						<div class="info-list-item-info">
							<div class="infos">
								<div class="infos-item">
									<div class="title">{{$t('poolPagePool2')}}</div>
									<div class="num">{{ numFormatter(item.day-item.remain) }} 天</div>
								</div>
								<div class="infos-item">
									<div class="title">{{$t('poolPagePool3')}}</div>
									<div class="num">{{ numFormatter(item.income) }} BNB</div>
								</div>
								<div class="infos-item">
									<div class="title">{{$t('poolPagePool4')}}</div>
									<div class="num">{{ item.date }}</div>
								</div>
							</div>
						</div>
						<van-progress :percentage="(((item.day-item.remain)/item.day)*100).toFixed(2)" pivot-color="#000" text-color="#ccec44"
							color="linear-gradient(to right, #ba5e0d, #edd45a)" />
					</div>
				</div>
				<div v-if="tabActive == 1">
					<div class="info-list-item" v-for="(item, index) in list" :key="index">
						<div class="info-list-item-title other">
							<div class="name">认购：{{ numFormatter(item.num) }} BNB</div>
							<!-- <span class="time">{{timestampToTime(item.date)}}</span> -->
						</div>
						<div class="info-list-item-info">
							<div class="infos">
								<div class="infos-item">
									<div class="title">{{$t('poolPagePool3')}}</div>
									<div class="num">{{ numFormatter(item.income) }} BNB</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="no-data" v-if="list.length == 0">
					<img src="@/image/nodata.png" alt="">
					<span>{{$t('nodata1')}}</span>
				</div>
			</div>
		</div>
		
		<popup v-model="popupShow1" :overlay="true" position="bottom" closeable @close="pledgeNum = ''">
			<div class="popup-content">
				<div class="popup-content-name">
					<img src="@/image/icon.png" alt="">
					<span>{{$t('poolPageBuy1')}}</span>
				</div>
				<div class="popup-content-total">
					<div class="popup-content-total-item">
						<div class="title">{{$t('poolPageBuy2')}}</div>
						<div class="num">{{ totalInfo.min_num }} <span class="symple">BNB</span></div>
					</div>
					<div class="popup-content-total-item">
						<div class="title">{{$t('poolPageBuy3')}}</div>
						<div class="num">{{ totalInfo.max_num }}  <span class="symple">BNB</span></div>
					</div>
				</div>
				<div class="popup-content-title">{{$t('buynum')}}</div>
				<div class="popup-content-inputs">
					<input type="number" :placeholder="$t('input')" v-model="pledgeNum">
					<div class="symple">BNB</div>
					<!-- <div class="allin" @click="allIn">{{$t('max')}}</div> -->
				</div>
				<div class="popup-content-balance other">
					<div>{{$t('balance')}}: <span class="num">{{balance.show}}</span> BNB</div>
				</div>
				<div class="popup-content-submit">
					<div class="btn" @click="submitBuy">{{$t('submit')}}</div>
				</div>
			</div>
		</popup>
		
		<popup v-model="outShow" :overlay="true" position="bottom" closeable @close="pledgeNum = ''">
			<div class="popup-content">
				<div class="popup-content-name">
					<img src="@/image/icon.png" alt="">
					<span>{{$t('withraw')}}</span>
				</div>
				<div class="popup-content-inputs" style="margin-top:15px">
					<input type="number" :placeholder="$t('input')" v-model="pledgeNum">
					<div class="symple">BNB</div>
					<!-- <div class="allin" @click="allIn1">{{$t('max')}}</div> -->
				</div>
				<div class="popup-content-balance other">
					<div>{{$t('can')}}:<span class="num">{{numFormatter(totalInfo.extract)}}</span> BNB</div>
					<div>{{$t('minnum')}}:<span class="num">{{totalInfo.multiple}}</span> BNB</div>
				</div>
				<div class="popup-content-submit" @click="submitOut">{{$t('sure')}}</div>
			</div>
		</popup>
		
		<popup v-model="bindShow" :overlay="true" :overlay-style="{zIndex:998}" position="bottom" :close-on-click-overlay="false" @close="upAddress=''" :style="{ bottom: '53px', zIndex: 999 }">
			<div class="popup-bind">
				<div class="popup-bind-title">{{$t('bindup')}}</div>
				<div class="popup-bind-input">
					<input type="text" v-model="upAddress" :placeholder="$t('bindup')">
				</div>
				<div class="popup-bind-btn flex align-items-center justify-content-center" @click="submitBind()">{{$t('confirm')}}</div>
			</div>
    </popup>
	</div>
</template>

<script>
var _this;
import Web3 from '@/utils/web3.js'
import Clipboard from 'clipboard';
import { formateTimeStamp } from '@/utils/utils.js'
import { Popup, Pagination,Popover } from 'vant';
import http from '@/utils/http.js'
import BigNumber from "bignumber.js";
export default {
	name: "home",
	components: {
		Popup,
		Pagination,
		Popover
	},
	data() {
		return {
			showPopover: false,
			oneShow: false,
			oneOut: false,
			outShow: false,
			selectType: 1,
			bindShow: true,
			upAddress: '',
			popupShow1: false,
			pledgeNum: '',
			produceNum: '',
			popupShow2: false,
			web3js: '',
			address: '',
			oneInfo: {
				rate: 0,
				day: 0,
				price: 0,
				isbuy: false,
				expected: 0,
				income: 0,
				is_close: false
			},
			totalInfo: {
				subscribe: 0,
				ega: 0,
				price: 0,
				dynamic: 0,
				all_income: 0,
				extract_income: 0,
				dynamic_income: 0,
				statice_income: 0,
				node_income: 0,
				rate: 0,
				today_num: 0,
				now_num: 0,
				min_num: 0,
			},
			poolInfo: {
				low: 0,
				max: 0,
				pool: []
			},
			poolChoose: 0,
			teamInfo: {
				people: 0,
				performance: 0,
				push: 0,
				paddress: ''
			},
			list: [],
			listTab: ['托管中', '已结束'],
			tabActive: 0,
			nowtime: 0,
			listChoose: 0,
			balance: {
				show: '',
				real: 0
			},
			poolSelect: null,
			poolContract: '0x67DB1B06E507603D19A27fA128c80969e629a38E',
			pool_instance: '',

		}
	},
	mounted() {
		this.init();
		if (this.$route.query.p) {
			this.upAddress = this.$route.query.p;
		}
    new Clipboard('#copy');
		this.listTab = [this.$t('tab1'), this.$t('tab2')];
	},
  beforeCreate() {
    _this = this;
  },
	filters: {
		limitTimeFilter(val) {
			if (val > 0) {
				let formateLimitTimes = formateTimeStamp(val);
				let txt = '';
				if (formateLimitTimes.day != 0) {
					txt = `${formateLimitTimes.day} ${_this.$t('tian')} ${formateLimitTimes.hour} ${_this.$t('shi')} ${formateLimitTimes.min} ${_this.$t('fen')}   ${formateLimitTimes.seconds} ${_this.$t('miao')}`;
				}else if (formateLimitTimes.hour != '00') {
					txt = `${formateLimitTimes.hour} ${_this.$t('shi')} ${formateLimitTimes.min} ${_this.$t('fen')}  ${formateLimitTimes.seconds} ${_this.$t('miao')}`;
				} else {
					txt = `${formateLimitTimes.min} ${_this.$t('fen')}   ${formateLimitTimes.seconds} ${_this.$t('miao')}`;
				}
				return txt;
			} else {
				const twtxt = `0 ${_this.$t('shi')} 0 ${_this.$t('fen')} 0 ${_this.$t('miao')}`
				return twtxt;
			}
		}
	},
  computed: {
		url() {
			return `${window.location.origin}/#/ecology?p=${this.address}`;
		}
	},
	watch: {
		'$i18n.locale'(newVal,oldVal) {
			this.listTab = [this.$t('tab1'), this.$t('tab2')];
		}
	},
	methods: {
		showPopup1() {
			this.popupShow1 = true;
		},
		showPopup2(i) {
			if (this.list[i].is_extract) {
				this.poolSelect = i;
				this.popupShow2 = true;
			}
		},
		oneWithraw() {
			if (this.oneInfo.isbuy&&this.oneInfo.is_close) return false
			this.oneOut = true
		},
		teamOut(type) {
			if ((type == 1&&this.totalInfo.extract_income == 0)||(type == 2&&this.totalInfo.node_income == 0)) return false;
			this.selectType = type;
			this.outShow=true;
		},
		init() {
			Web3.getWeb3().then(async res => {
				this.web3js = res;
				try {
					const result = await window.ethereum.enable();
					this.address = result[0];
					this.$store.commit("setAddress", this.address);
					this.getAccount();
				} catch (error) {
					this.$toast(this.$t('toast13'))
				}
			}).catch(err => {
				this.$toast(err.message)
			})
		},
		async getAccount() {
			const poolabi = require('@/utils/web3.json');
			const withdrawabi = require('@/utils/withdraw.json');
      this.pool_instance = await new this.web3js.eth.Contract(poolabi, this.poolContract, {
          from: this.address,
			});
			this.web3js.eth.getBalance(this.address).then(v => {
				this.balance.real = v;
				this.balance.show = (BigNumber(Number(v))/BigNumber(Math.pow(10,18))).toFixed(4);
			});
			http.get('/ecology/team', { address: this.address }).then(({ data }) => {
				if (data.errno == 200) {
					this.teamInfo = data.data;
					if (this.teamInfo.paddress) {
						this.bindShow = false;
						http.get('/ecology/income', { address: this.address }).then(({ data }) => {
							if (data.errno == 200) {
								this.totalInfo = data.data;
							}
						})
						this.getList();
					}
				}
			})
		},
		getList() {
			if (this.tabActive == 0) {
				http.get('/ecology/list', { address: this.address }).then(({ data }) => {
					if (data.errno == 200) {
						if (data.data!=null){
							this.list = data.data;
						} else {
							this.list = []
						}
					}
				})
			} else {
				http.get('/ecology/close', { address: this.address }).then(({ data }) => {
					if (data.errno == 200) {
						if (data.data!=null){
							this.list = data.data;
						} else {
							this.list = []
						}
					}
				})
			}
		},
		submitRedeem(mark) {
			this.$loading.show();
			http.post('/ecology/getPool', {address: this.address,mark}).then(({ data }) => {
				if (data.errno == 200) {
					this.pool_instance.methods.withdraw(data.data.num,data.data.date,data.data.sign).send().then(res => {
						this.$loading.hide();
						this.outShow = false;
						this.getAccount();
						this.$toast(this.$t('toast10'));
					}).catch(err => {
						this.$loading.hide();
						this.$toast(err.message)
					})
				} else {
					this.$loading.hide();
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitOut() {
			const  balance = this.totalInfo.extract;
			if (!this.pledgeNum) return this.$toast(this.$t('alert4'));
			if (Number(this.pledgeNum)>Number(balance)) return this.$toast(this.$t('alert5'));
			if (this.totalInfo.multiple>this.pledgeNum) return this.$toast(this.$t('minnum')+':'+this.totalInfo.multiple);
			this.$loading.show();
			http.post('/ecology/getIncome', {address: this.address,num: Number(this.pledgeNum)}).then(({ data }) => {
				if (data.errno == 200) {
					this.pool_instance.methods.withdraw(data.data.num,data.data.date,data.data.sign).send().then(res => {
						this.$loading.hide();
						this.outShow = false;
						this.getAccount();
						this.$toast(this.$t('toast10'));
					}).catch(err => {
						this.$loading.hide();
						this.$toast(err.message)
					})
				} else {
					this.$loading.hide();
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitBind() {
			if (!this.upAddress) return this.$toast(this.$t('alert3'));
			this.$loading.show();
			http.post('/ecology/bind', { superior: this.upAddress,address: this.address}).then(({ data }) => {
				this.$loading.hide();
				if (data.errno == 200) {
					this.bindShow = false;
					this.getAccount();
				} else {
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitBuy() {
			if (!this.pledgeNum) return this.$toast(this.$t('alert4'));
			if (Number(this.pledgeNum)>Number(this.balance.show)) return this.$toast(this.$t('alert5'));
			if (Number(this.pledgeNum)<Number(this.totalInfo.min_num)) return this.$toast(this.$t('poolPageBuy2')+this.totalInfo.min_num+'BNB');
			if (Number(this.pledgeNum)>Number(this.totalInfo.max_num)) return this.$toast(this.$t('poolPageBuy3')+this.totalInfo.max_num+'BNB');
			this.$loading.show();
			this.pool_instance.methods._openAPond(this.totalInfo.rate, this.totalInfo.fee).send({value: BigNumber(Number(this.pledgeNum)*Math.pow(10,18)).toFixed(0)}).then(res => {
				this.$loading.hide();
				this.popupShow1 = false;
				this.getAccount();
				this.$toast(this.$t('toast10'));
			}).catch(err => {
				this.$loading.hide();
				this.$toast(err.message)
			})
		},
		changeTab(i) {
			this.tabActive = i;
			this.list = [];
			this.getList();
		},
		allIn() {
			this.pledgeNum = this.balance.show;
		},
		allIn1() {
			this.pledgeNum = (this.selectType == 1 ? (this.totalInfo.extract_income < this.totalInfo.today_num ? this.totalInfo.extract_income : this.totalInfo.today_num) : this.totalInfo.node_income);
		},
    handleCopy() {
      this.$toast(this.$t('copySuccess'));
    },
		returnBalance(val) {
			return parseFloat((Math.floor(val * 1000000) / 1000000).toFixed(6))
		},
		hideAddress(address) {
			if (address) {
				return address.substring(0, 6) + '...' + address.substring(address.length - 6, address.length)
			} else {
				return ''
			}
		},
		hideUrl(url) {
			if (url) {
				return url.substring(0, 20) + '...' + url.substring(url.length - 10, url.length)
			} else {
				return ''
			}
		},
		timestampToTime(timestamp) {
			var date = new Date(timestamp * 1000);
			var Y = date.getFullYear() + "-";
			var M =
				(date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1)
					: date.getMonth() + 1) + "-";
			var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			var h = date.getHours() + ":";
			var m = date.getMinutes();
			return Y + M + D + h + m;
		},
		numFormatter(num, digits = 2) {
      if (!num) return 0;
			const si = [
				{ value: 1, symbol: "" },
				{ value: 1E3, symbol: "K" },
				{ value: 1E6, symbol: "M" },
				{ value: 1E9, symbol: "G" },
				{ value: 1E12, symbol: "T" },
				{ value: 1E15, symbol: "P" },
				{ value: 1E18, symbol: "E" }
			];
			const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
			let i;
			for (i = si.length - 1; i > 0; i--) {
				if (num >= si[i].value) {
					break;
				}
			}
			if (num < 1000) return num;
			return (BigNumber((num / si[i].value) * (Math.pow(10, digits))) / (Math.pow(10, digits))).toFixed(digits).replace(rx, " $1") + si[i].symbol;
		}
	}
};
</script>

<style lang="less" scoped>
.home-container {
	background: #151515;
	.top-content {
		padding: 0 20px;
		width: 100%;
		height: 180px;
		background-image: url('../image/topbg.png');
		background-size: 100% 100%;
		padding-top: 10px;
		display: flex;
		align-items: center;
		color: #3B3F49;
	}

	.info {
		padding: 0 20px 30px;
		margin-top: -65px;

		&-total {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-item {
				width: 140px;
				height: 100px;
				background: #3B3F49;
				border-radius: 10px;
				padding: 5px 15px;

				&.other {
					width: 180px;
				}

				.item {
					height: 60px;
					display: flex;
					flex-direction: column;
          justify-content: space-between;

          &.total {
            height: 100%;
          }

					&.border {
						border-top: 1Px solid #e9edf4;
					}

					.title {
						font-size: 12px;
						color: #8392a1;
						line-height: 2;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.value {
							font-size: 8px;
							font-weight: normal;
							color: #6b4e02;
						}
						.van-icon{
							font-size: 20px;
							margin-right: 10px;
						}
					}

					.num {
						font-size: 18px;
						font-weight: bold;
						color: #fff;
						line-height: 1.5;

						.symple {
							font-size: 14px;
							color: #8392a1;
						}

						.value {
							font-size: 8px;
							font-weight: normal;
							color: #8392a1;
						}
					}

					.btn {
						width: 70px;
						height: 28px;
						border-radius: 14px;
						background-color: #ccec44;
						font-size: 11px;
						color: #000;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}

		&-team {
			margin-top: 15px;
			width: 100%;
			height: 105px;
			border-radius: 10px;
			background-image: linear-gradient(90deg, rgb(101, 102, 97) 0%, rgba(37, 37, 37, 0) 100%);
			position: relative;
			padding: 0 15px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 12px;
			color: #ccec44;
			line-height: 1.8;
			.btn{
        color: #fff;
        align-items: center;
        font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.copy{
					color: #ccec44;
					border-bottom: 1px solid #ccec44;
					line-height: 1;
					position: relative;
					z-index: 1;
					margin-bottom: 5px;
				}
			}
			.btn1{
				width: 100px;
        padding: 8px;
        border-radius: 14px;
        line-height: 1;
        background: #ccec44;
        color: #fff;
        display: flex;
        align-items: center;
				justify-content: center;
        font-size: 12px;
			}

			.coin {
				font-weight: bold;
			}

			.num {
				color: #8392a1;

				.value {
					color: #fff;
				}
			}

			.img {
				position: absolute;
				width: 110px;
				bottom: 0;
				right: 0;
			}
		}

		&-title {
			height: 50px;
			padding-top: 10px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #fff;
			font-weight: bold;
		}

		&-one {
			width: 100%;
			height: 70px;
			border-radius: 10px;
			background-image: linear-gradient(90deg, #0556fd 0%, #029cfe 100%);
			display: flex;
			justify-content: space-between;
			padding: 0 15px;

			.title {
				font-size: 10px;
				color: #8abada;
			}

			.num {
				font-size: 18px;
				font-weight: bold;
				color: #000;
				line-height: 1.5;

				.symple {
					font-size: 12px;
				}

				&.tipsnun {
					margin-top: 10px;
				}
			}

			.btn {
				width: 70px;
				height: 28px;
				border-radius: 14px;
				background-color: rgba(255, 255, 255, 0.2);
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 11px;
				color: #000;
			}

			.item {
				width: 25%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.tips {
				width: 70px;
				height: 17px;
				background-color: #f9d83d;
				border-radius: 0 6px 0 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #6b4e02;
				margin-top: -10px;
				margin-left: -20px;
			}
		}

		&-pool {
			width: 100%;
			border-radius: 10px;
			background-color: #3B3F49;
			margin-top: 20px;
			padding: 15px;
			box-shadow: 0 0 10px #b3d1fe;

			&-title {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.name {
					display: flex;
					align-items: center;
					font-size: 14px;
					color: #fff;

					img {
						width: 24px;
						margin-right: 7.5px;
					}
				}

				.num {
					color: #1ba909;
					font-size: 14px;
				}
			}

			.tips {
				font-size: 11px;
				color: #aeb8c6;
				line-height: 1;
				padding: 20px 0 15px 0;
			}

			&-btns {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.btn {
					width: 70px;
					height: 28px;
					border-radius: 5px;
					background: #f0f3f7;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					color: #fff;

					&.active {
						border: 1px solid #ccec44;
						position: relative;
						color: #ccec44;

						img {
							position: absolute;
							width: 16px;
							top: -1px;
							right: -1px;
						}
					}
				}
			}

			&-submit {
				width: 305px;
				height: 37px;
				border-radius: 5px;
				background: #ccec44;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13px;
				color: #000;
				margin-top: 18px;
			}
		}

		&-title1 {
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: bold;
			color: #ccd2da;
		}

		&-list {
			background: #3B3F49;
			border-radius: 10px;
			overflow: hidden;

			&-tab {
				width: 100%;
				height: 40px;
				display: flex;
				background: #55575b;

				&-item {
					height: 100%;
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					color: #aeb8c6;

					&.active {
						border-radius: 10px 10px 0 0;
						background: #3B3F49;
						color: #fff;
					}
				}
			}

			&-btn {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 25px;

				.btn {
					font-size: 11px;
					color: #aeb8c6;
					border: 1px solid #aeb8c6;
					width: 60px;
					height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5px;

					&.active {
						color: #ccec44;
						border: 1px solid #ccec44;
					}
				}
			}

			&-item {
				margin: 0 20px;
				padding: 15px 0;

				&-title {
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					font-weight: bold;
					color: #fff;
					padding-left: 20px;
					position: relative;

					&::before {
						content: '';
						width: 10px;
						height: 10px;
						border-radius: 5px;
						background: #ccec44;
						position: absolute;
						left: 0;
						top: 12.5px;
					}

					&.other {
						&::before {
							background: #636971;
						}
					}

					.time {
						font-size: 10px;
						color: #aeb8c6;
						font-weight: normal;
					}

					.close {
						font-size: 11px;
						color: #aeb8c6;
						line-height: 1;
						border-bottom: 1px solid #aeb8c6;
						font-weight: normal;

						&.other {
							border: none;
							color: #f9d83d;
						}
					}
					.btn {
						font-size: 11px;
						width: 60px;
						height: 22px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 5px;
						color: #ccec44;
						border: 1px solid #ccec44;
					}
				}

				&-time {
					padding: 0 15px;
					height: 20px;
					background: #f9d83d;
					border-radius: 10px;
					font-size: 10px;
					color: #6b4e02;
					display: inline-flex;
					align-items: center;
					justify-content: center;

					&.other {
						background: #626364;
						color: #c8d0db;
					}
				}

				&-info {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.infos {
						padding: 15px 0;

						&-item {
							display: flex;
							padding: 5px 0;

							.title {
								width: 95px;
								font-size: 11px;
								color: #aeb8c6;
							}

							.num {
								font-size: 12px;
								color: #fff;
							}
						}
					}

					.btn {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 80px;
						height: 28px;
						border-radius: 14px;
						background: #ccec44;
						font-size: 11px;
						color: #000000;

						&.other {
							background: #626364;
							color: #c8d0db;
						}
					}
				}
			}
		}
	}
}

.popup-content {
	width: 100%;
	height: 100%;
	padding: 20px;
	background: #3B3F49;

	&-name {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #fff;

		img {
			width: 24px;
			margin-right: 7.5px;
		}

		.tips {
			width: 70px;
			height: 17px;
			background-color: #f9d83d;
			border-radius: 0 6px 0 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #6b4e02;
			font-size: 10px;
			margin-left: 15px;
		}
	}

	&-info {
		height: 90px;
		padding-top: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e9edf4;

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.title {
				font-size: 12px;
				color: #88919c;
				line-height: 2;
			}

			.num {
				font-size: 16px;
				font-weight: bold;
				color: #fff;

				.symple {
					font-size: 12px;
					color: #88919c;
					font-weight: normal;
				}
			}
		}
	}

	&-balance {
		height: 40px;
		display: flex;
		align-items: center;
		font-size: 12px;
		color: #88919c;
		.num {
			color: #fff;
		}
		&.other{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&-title {
		height: 40px;
		display: flex;
		align-items: center;
		font-size: 12px;
		color: #88919c;
	}

	&-submit {
		width: 100%;
		height: 44px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		color: #000;
		background: #ccec44;
		margin-top: 25px;
	}
	&-submits{
		margin-top: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn {
			width: 100%;
			height: 44px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
			color: #000;
			background: #ccec44;
		}
	}


	&-total {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 0;
		border-bottom: 1px solid #e9edf4;

		&-item {
			width: 70px;

			.title {
				font-size: 12px;
				color: #88919c;
			}

			.num {
				font-size: 14px;
				font-weight: bold;
				color: #fff;

				.symple {
					font-size: 12px;
					color: #88919c;
					font-weight: normal;
				}
			}
		}

		&-progress {
			width: 180px;
		}
	}

	&-inputs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 44px;
		border-radius: 5px;
		padding: 0 10px;
		background: #f0f3f7;

		input {
			width: 230px;
			font-size: 14px;
      color: #000;
		}

		.symple {
			font-size: 14px;
			color: #88919c;
		}

		.allin {
			font-size: 12px;
			color: #ccec44;
			font-weight: bold;
		}
	}

	&-out {
		display: flex;
		justify-content: center;
		padding: 20px 0;
		border-bottom: 1px solid #e9edf4;

		.income {
			text-align: center;

			.title {
				font-size: 12px;
				color: #8392a1;
				line-height: 2;
			}

			.num {
				font-size: 18px;
				font-weight: bold;
				color: #fff;
				line-height: 1.5;
			}
		}
	}

	&-income {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 20px 0;
		border-bottom: 1px solid #e9edf4;

		.income {
			.title {
				font-size: 12px;
				color: #8392a1;
				line-height: 2;
			}

			.num {
				font-size: 18px;
				font-weight: bold;
				color: #fff;
				line-height: 1.5;

				.symple {
					font-size: 14px;
					color: #8392a1;
				}
			}
		}

		.time {
			padding: 0 15px;
			height: 20px;
			background: #f9d83d;
			border-radius: 10px;
			font-size: 10px;
			color: #6b4e02;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}

	&-tips {
		height: 28px;
		display: flex;
		align-items: center;
		font-size: 11px;
		color: #ccec44;
		background: linear-gradient(to right, #e5f0ff, #fff);
	}
}
.popup-bind{
	width: 100%;
	padding: 20px;
	padding-bottom: 30px;
	background-color: #242424;
	background: #3B3F49;
	&-title{
		font-size: 18px;
		color: #fff;
		font-weight: bold;
		line-height: 40px;
		text-align: center;
	}
	&-input{
		width: 100%;
		height: 44px;
		border-radius: 5px;
		border: 1px solid #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		margin-top: 20px;
		input{
			width: 100%;
			font-size: 12px;
			color: #fff;
		}
	}
	&-btn{
		width: 100%;
		height: 44px;
		border-radius: 5px;
		background: #ccec44;
		font-size: 13px;
		color: #000;
		margin-top: 20px;
	}
}

.no-data {
	display: flex;
	justify-content: center;
	padding: 80px 0;
	color: #aeb8c6;
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
		width: 50px;
		margin-bottom: 15px;
	}
}
.popover-content{
	padding: 5px 15px;
	.popover-title{
		width: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		font-size: 12px;
		color: #c6c6c6;
		display: flex;
	}
}
.van-overlay {
  z-index: 999 !important;
}
</style>